/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 25/10/2021
 * @Example
 */

import { Column } from 'devextreme-react/data-grid';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    DatePicker,
    DateRangePicker,
    Dropdown,
    TextInput,
    Tooltip,
} from 'diginet-core-ui/components';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../config';
import * as approvalActions from '../../../../redux/approvals/approvals_actions';
import * as generalActions from '../../../../redux/general/general_actions';
import Approvals from '../../../approvals/approvals';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';
import GridContainer from '../../../grid-container/grid-container';

const W29F2088 = (props) => {
    const { location } = props;

    const [iPermission, setIPermission] = useState(0);
    const [dataForm, setDataForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });
    const [filter, setFilter] = useState(() => ({
        ApprovalStatus: null,
        EmployeeID: null,
        DateFrom: null,
        DateTo: null,
    }));
    const [dataFilter, setDataFilter] = useState(() => {
        return {
            UserID: Config?.profile?.UserID ?? '',
            ApprovalStatus: filter?.ApprovalStatus ?? null,
            EmployeeID: filter?.EmployeeID ?? '',
            DateFrom: filter?.DateFrom ?? null,
            DateTo: filter?.DateTo ?? null,
        };
    });

    const toolFilter = useRef(null);
    const approvalRef = useRef(null);

    const dispatch = useDispatch();
    const dataCboStatus = useSelector((state) => state?.approvals?.getCboStatus ?? []);

    const paramFilterCboEmployees = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission('W29F2088', (isPer) => {
                setIPermission(isPer);
            })
        );
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const VoucherID = location?.state?.voucher_id ?? null;
        if (VoucherID) {
            loadFormInfo(VoucherID, (dt) => {
                dt.VoucherID = VoucherID;
                approvalRef.current && approvalRef.current.showPopup(dt);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const loadCboStatus = () => {
        const params = {
            FormID: 'STANDARD',
            Language: Config.language || '84',
        };
        dispatch(
            approvalActions.getCboStatus(params, (error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W29F2088',
            Language: Config.language || '84',
            ...paramFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const onLoadCboFilter = () => {
        loadCboStatus();
        loadCboEmployees();
    };

    const renderFilter = () => {
        const { EmployeeID, ApprovalStatus, DateFrom, DateTo } = filter;
        return (
            <Filter
                isUseDDCore
                dropdownProps={{
                    ref: toolFilter,
                }}
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <DateRangePicker
                                clearAble
                                controls
                                value={[DateFrom ?? null, DateTo ?? null]}
                                label={Config.lang('Ngay')}
                                onChange={(e) => filterChange('Date', e)}
                                placeholder={`${Config.lang('Tu_ngay')} - ${Config.lang('Den_ngay')}`}
                                returnFormat={'YYYY-MM-DD'}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                clearAble
                                valueExpr={'ApprovalStatus'}
                                keyExpr={'AppStatusName'}
                                displayExpr={'AppStatusName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Trang_thai_duyet')}
                                onChange={(e) => filterChange('ApprovalStatus', e)}
                                dataSource={dataCboStatus}
                                value={ApprovalStatus ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                allowSearch
                                clearAble
                                dataSource={dataCboEmployees.rows}
                                total={dataCboEmployees.total}
                                skip={paramFilterCboEmployees.skip}
                                limit={paramFilterCboEmployees.limit}
                                displayExpr={'{EmployeeID} - {EmployeeName}'}
                                keyExpr={'EmployeeName'}
                                valueExpr={'EmployeeID'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Nhan_vien')}
                                onChange={(e) => filterChange('EmployeeID', e)}
                                value={EmployeeID ?? null}
                                viewType={'outlined'}
                                loading={loadingCboEmployees}
                                onInput={(e) => {
                                    paramFilterCboEmployees.search = e?.target?.value ?? '';
                                    paramFilterCboEmployees.skip = 0;
                                    loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    paramFilterCboEmployees.skip = e?.skip ?? 0;
                                    paramFilterCboEmployees.limit = e?.limit ?? 10;
                                    loadCboEmployees();
                                }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    text={Config.lang('Tim_kiem')}
                                    viewType={'outlined'}
                                    color={'primary'}
                                    onClick={onSearch}
                                    startIcon={'Search'}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const filterChange = (key, data) => {
        switch (key) {
            case 'EmployeeID':
            case 'ApprovalStatus':
                setFilter({ ...filter, [key]: data?.value ?? null });
                break;
            case 'Date':
                setFilter({ ...filter, DateFrom: data?.value?.[0] ?? null, DateTo: data?.value?.[1] ?? null });
                break;
            default:
                break;
        }
    };

    const onSearch = () => {
        setDataFilter({ ...dataFilter, ...filter });
        if (toolFilter.current) toolFilter.current.close();
    };

    const onSelectionChanged = (e) => {
        if (!e) return false;
        loadFormInfo(e.VoucherID);
    };

    const loadFormInfo = (voucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID() ?? '',
            FormID: 'W29F2088',
            UserID: Config?.profile?.UserID ?? '',
            Language: Config?.language ?? '84',
            VoucherID: voucherID ?? '',
        };
        setLoading(true);
        dispatch(
            approvalActions.getFormInfo(params, (err, dt) => {
                setLoading(false);
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup.show('ERROR', msg);
                }
                if (dt) {
                    setDataForm(dt);
                    cb && cb(dt);
                }
            })
        );
    };

    const renderFormInfo = (data) => {
        return (
            <>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4}>
                        <DatePicker
                            readOnly
                            required
                            placeholder={'DD/MM/YYYY'}
                            displayFormat={'DD/MM/YYYY'}
                            label={Config.lang('Ngay')}
                            value={data?.AttendanceDate ?? null}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                        <Tooltip title={data?.Reason ?? ''} style={{ width: 'auto' }}>
                            <TextInput readOnly label={Config.lang('Ly_do')} value={data?.Reason ?? ''} />
                        </Tooltip>
                    </Col>
                </Row>
                <Tooltip title={data?.Note ?? ''} style={{ width: 'auto' }}>
                    <TextInput readOnly label={Config.lang('Ghi_chu')} value={data?.Note ?? ''} />
                </Tooltip>
                <Accordion expand>
                    <AccordionSummary>{Config.lang('Danh_sach_nhan_vien')}</AccordionSummary>
                    <AccordionDetails>
                        <GridContainer
                            dataSource={data?.AbsentType ?? []}
                            keyExpr={'EmployeeID'}
                            typePaging={'normal'}
                            pagerFullScreen={false}
                            showBorders={false}
                            showColumnLines={false}
                            showRowLines={false}
                            hoverStateEnabled={true}
                            height={400}
                            style={{ marginTop: 8, border: 'none' }}
                        >
                            <Column dataField={'EmployeeID'} caption={Config.lang('Ma_nhan_vien')} />
                            <Column dataField={'EmployeeName'} caption={Config.lang('Ten_nhan_vien')} />
                            <Column dataField={'AttendanceDate'} dataType={'date'} format={'dd/MM/yyyy'} caption={Config.lang('Ngay_di_ca')} />
                            <Column dataField={'ShiftInOut'} caption={Config.lang('Ca_vao_ra')} />
                            <Column dataField={'LateMinute'} caption={Config.lang('Di_tre')} />
                            <Column dataField={'EarlyMinute'} caption={Config.lang('Ve_som')} />
                            <Column dataField={'InLateMinutes'} caption={Config.lang('Vao_tre')} />
                            <Column dataField={'OutEarlyMinutes'} caption={Config.lang('Ra_som')} />
                            <Column dataField={'Note'} caption={Config.lang('Ghi_chu')} width={300} />
                            <Column dataField={'Reason'} caption={Config.lang('Ly_do')} width={300} />
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
            </>
        );
    };

    return (
        <>
            <ActionToolbar title={Config.lang('Duyet_di_tre_ve_som_hang_loat')} />
            <Approvals
                ref={approvalRef}
                parentProps={props}
                FormID={'W29F2088'}
                dataFilter={dataFilter}
                dataForm={dataForm}
                loading={loading}
                singleClick
                // allowHistoryApproval
                isActionPermission={iPermission >= 2}
                filterRender={renderFilter}
                formInfoRender={renderFormInfo}
                onSelectionChanged={onSelectionChanged}
            />
        </>
    );
};

export default W29F2088;
