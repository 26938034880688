import React, {useState, useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import {makeStyles} from "diginet-core-ui/theme";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import {Button, ButtonIcon, DateRangePicker, Dropdown, Tooltip, Typography} from "diginet-core-ui/components";
import GridContainer from "../../../grid-container/grid-container";
import _ from "lodash";
import {Column} from "devextreme-react/data-grid";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W29F2085Actions from "../../../../redux/W2X/W29F2085/W29F2085_actions";
import Filter from "../../../filter/filter";
import Status from "../../../common/status/status";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W29F2086 from "../W29F2086/W29F2086";
import moment from "moment";

const useStyles = makeStyles(() => ({
    dateInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    divText: {
        fontWeight: 500,
    },
    truncatedText: {
        maxWidth: 450,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));
const FormID = "W29F2085";
const Language = Config.language || "84";

const W29F2085 = ({w29F2085Actions, generalActions, getCboAppStatus, location}) => {
    const classes = useStyles();
    const [dataGrid, setDataGrid] = useState({rows: [], total: 0});

    const [paging, setPaging] = useState({skip: 0, limit: 10});

    const [isLoadingDataGrid, setLoadingDataGrid] = useState(false);
    const [isLoadingCbAppStatus, setLoadingCbAppStatus] = useState(false);

    const [iPermission, setIsPermission] = useState(0);

    const [showW29F2086, setShowW29F2086] = useState({isShowing: false, data: {}, mode: ""});
    const [showW84F3005, setShowW84F3005] = useState({isShowing: false, transID: ""})

    const [filters, setFilters] = useState({
        AppStatusID: "",
        DateFrom: null,
        DateTo: null,
    });

    const [strFiltering, setStrFiltering] = useState("reset");

    useEffect(() => {
        (async () => {
            await generalActions.getPermission("W29F2085", (isPer) => {
                setIsPermission(isPer);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const omitNullEmptyObj = (obj) => _(obj).mapValues(_.toString).omitBy(_.isEmpty);

    const loadGrid = () => {
        const {DateFrom, DateTo, AppStatusID} = filters;
        const {skip, limit} = paging;
        const params = {
            FormID,
            Language,
            DateFrom,
            DateTo,
            ShiftID: "",
            TransID: "",
            AppStatusID,
            skip,
            limit
        };
        setLoadingDataGrid(true);
        w29F2085Actions.loadDataGrid(omitNullEmptyObj(params), (err, data) => {
            setLoadingDataGrid(false);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                setDataGrid({rows, total});
            }
        });
    };

    useEffect(() => {
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging.skip, paging.limit, strFiltering]);

    useEffect(() => {
        setLoadingCbAppStatus(true)
        generalActions.getCboAppStatus({FormID: "STANDARD", Language}, (err) => {
            setLoadingCbAppStatus(false)
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Config.callChildForm({
            FormID: "W29F2085",
            keyExpr: "TransID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onAction: (e, data) => onOpenW29F2086("view", data),
        }, {location});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const onChangePage = (page) => setPaging({...paging, skip: page * paging.limit});

    const onChangePerPage = (perPage) => setPaging({skip: 0, limit: perPage});

    const onFilterChange = (key, e) => {
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        let filtersValue;
        if (key === "Date") {
            filtersValue = {
                ...filters,
                DateFrom: _.get(value, "[0]", null),
                DateTo: _.get(value, "[1]", null)
            }
        } else {
            filtersValue = {...filters, [key]: value};
        }
        if (!omitNullEmptyObj(filtersValue).size()) setStrFiltering("reset");
        setFilters(filtersValue);
    };

    const onFiltering = () => setStrFiltering(_.join(_.values(filters), "-"));

    const onDelete = (data) => {
        const TransID = _.get(data, "TransID", "");
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            w29F2085Actions.deleteRow({TransID}, (err) => {
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                }
                Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                setStrFiltering(`deleted ${TransID}`);
            })
        });
    };

    const onCancel = (data) => {
        const attendanceDate = moment(data?.AttendanceDate, "DD/MM/YYYY").isValid() ?
            moment(data.AttendanceDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
        const params = {
            TransID: data?.TransID ?? "",
            FormID: FormID,
            AttendanceDate: attendanceDate,
            ApprovalLevel: data?.ApprovalLevel || null,
        };

        Config.popup.show("YES_NO", Config.lang("Ban_co_muon_huy_phieu_nay?"), () => {
            w29F2085Actions.onCancel(params, (err, data) => {
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                }
                if(data?.Status === 1) {
                    Config.popup.show("INFO", data.Message);
                    return false;
                }
                Config.notify.show("success", Config.lang("Huy_thanh_cong"), 3000);
                loadGrid();
            })
        });

    };

    const onOpenW29F2086 = (mode = "add", data = {}) => setShowW29F2086({isShowing: true, data, mode});

    const onCloseW29F2086 = (isReload = false) => {
        if (isReload) setStrFiltering("reload");
        setShowW29F2086({isShowing: false, data: {}, mode: ""})
    }

    const renderEmpProfile = (e) => {
        const {data} = e.row;
        const dataEmployee = Config.getUser({UserID: _.get(data, "CreateUserID", "")});
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={dataEmployee}/>
                <div className={"w09f2280_info"}>
                    <div className={classes.divText}>
                        <UserName data={dataEmployee}/>
                        <Typography>{dataEmployee?.EmployeeID || ""}</Typography>
                    </div>
                </div>
            </div>
        );
    };

    const renderInfo = (e) => {
        const {data} = e.row;
        const descContent = [Config.lang("Ly_do"), Config.lang("Ghi_chu")];
        const dataInfo = _.filter([
            {
                label: Config.lang("Ngay"),
                value: data?.AttendanceDate
            },
            {
                label: descContent[0],
                value: data?.Reason
            },
            {
                label: descContent[1],
                value: data?.ApprovalNote
            }
        ], item => !!item.value);
        return <>
            {data?.TransTypeID === "cancel" ? (
                <Typography color={"violet5"}>{Config.lang("Huy_di_tre_ve_som")}</Typography>
            ) : (
                <Typography color={"warning"}>{Config.lang("Dang_ky_di_tre_ve_som")}</Typography>
            )}
            {_.map(dataInfo, (item, i) => {
                const isDesContent = _.includes(descContent, item.label);
                return (<Tooltip key={i}
                                 direction="down"
                                 alignMode={"flex-start"}
                                 forceDirection
                                 title={isDesContent ? item.value : ""}
                >
                    <div key={i} style={{display: "flex", alignItems: "center"}}>
                        <div>{item.label}</div>
                        <div style={{paddingRight: 5}}>:</div>
                        <div className={isDesContent ? classes.truncatedText : ""}>{item.value}</div>
                    </div>
                </Tooltip>)
            })}
        </>;
    };

    const renderAppStatus = (e) => {
        const {data} = e.row;
        const status = getCboAppStatus.find(rs => Number(rs.AppStatusID) === Number(data.AppStatusID));
        return status && <Status data={status}/>;
    }

    const renderActions = (e) => {
        const {
            data,
            data:{IsHideCancel}
        } = e.row;
        const getCreateUserID = _.get(data, "CreateUserID", "");
        const userID = Config.getHREmployeeID();
        const getAppStatusID = +_.get(data, "AppStatusID", 1);
        const isDisabledView = (iPermission < 3 && userID !== getCreateUserID) || getAppStatusID !== 0;
        const isDisabledDelete = (iPermission < 4 && userID !== getCreateUserID) || getAppStatusID !== 0;
        const isDisabledCancel = iPermission < 3 || getAppStatusID !== 2;

        return (<div className={"display_row align-center"}>
            <Tooltip direction="down" title={Config.lang("Xem_lich_su")}>
                <ButtonIcon
                    className={"mgr5"}
                    circular={true}
                    size={"medium"}
                    viewType={"text"}
                    disabled={iPermission < 1}
                    name={"History"}
                    onClick={() => setShowW84F3005({isShowing: true, transID: _.get(data, "TransID", "")})}
                />
            </Tooltip>
            <Tooltip direction="down" title={Config.lang("Xem_chi_tiet")}>
                <ButtonIcon
                    className={"mgr5"}
                    disabled={isDisabledView}
                    circular={true}
                    size={"medium"}
                    viewType={"text"}
                    name={"View"}
                    onClick={() => onOpenW29F2086("edit", data)}
                />
            </Tooltip>
            <Tooltip direction="down" title={Config.lang("Xoa")}>
                <ButtonIcon
                    className={"mgr5"}
                    disabled={isDisabledDelete}
                    circular={true}
                    size={"medium"}
                    viewType={"text"}
                    name={"Delete"}
                    onClick={() => onDelete(data)}
                />
            </Tooltip>
            {!IsHideCancel &&  
                <Tooltip direction="down" title={Config.lang("Huy")}>
                    <ButtonIcon
                        disabled={isDisabledCancel}
                        circular={true}
                        size={"medium"}
                        viewType={"text"}
                        name={"Close"}
                        onClick={() => onCancel(data)}
                    />
                </Tooltip>
            }
        </div>)
    }

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={true}
                isUseDDCore={true}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12}>
                                    <DateRangePicker
                                        clearAble
                                        controls
                                        value={[filters.DateFrom, filters.DateTo]}
                                        label={Config.lang('Ngay')}
                                        onChange={(e) => onFilterChange("Date", e)}
                                        placeholder={`${Config.lang('Tu_ngay')} - ${Config.lang('Den_ngay')}`}
                                        returnFormat={'YYYY-MM-DD'}
                                        viewType={'outlined'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Dropdown
                                        clearAble
                                        viewType={"outlined"}
                                        valueExpr={"AppStatusID"}
                                        displayExpr={"AppStatusName"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Trang_thai_duyet")}
                                        onChange={e => onFilterChange("AppStatusID", e)}
                                        loading={isLoadingCbAppStatus}
                                        dataSource={getCboAppStatus}
                                        value={filters.AppStatusID}
                                    />
                                </Col>
                            </Row>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={onFiltering}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    return (<>
            {showW29F2086.isShowing ? <W29F2086
                data={showW29F2086.data}
                open={showW29F2086.isShowing}
                mode={showW29F2086.mode}
                onClose={onCloseW29F2086}
                iPermissionW29F2085={iPermission}
            /> : ""}
            {showW84F3005.isShowing ?
                <W84F3005 open={showW84F3005.isShowing} onClose={() => setShowW84F3005({isShowing: false, transID: ""})}
                          FormID={"W29F2085"} TransID={showW84F3005.transID}/> : ""}
            <div className={"hidden"}>{renderFilter()}</div>
            <ActionToolbar title={Config.lang("Di_tre_ve_som_hang_loat")}>
                <Button
                    disabled={iPermission < 2}
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    label={Config.lang("Them")}
                    startIcon={"AddCircle"}
                    onClick={() => onOpenW29F2086("add")}
                />
            </ActionToolbar>
            <GridContainer
                keyExpr={"TransID"}
                style={{border: "none"}}
                dataSource={dataGrid.rows}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                noDataText={Config.lang("No_data")}
                loading={isLoadingDataGrid}
                hoverStateEnabled={true}
                totalItems={dataGrid.total}
                height={Config.getHeightGrid()}
                itemPerPage={paging.limit}
                skipPerPage={paging.skip}
                typePaging={"remote"}
                onDbRowClick={({data}) => onOpenW29F2086("view", data)}
            >
                <Column
                    width={110}
                    alignment={"center"}
                    visible={Config.isMobile}
                    allowEditing={false}
                    cellRender={renderActions}
                />
                <Column cellRender={renderEmpProfile} width={300}/>
                <Column cellRender={renderInfo} minWidth={120}/>
                <Column cellRender={renderAppStatus} alignment="right" width={200}/>
                <Column
                    alignment={"center"}
                    fixed={true}
                    fixedPosition={"right"}
                    width={200}
                    visible={!Config.isMobile}
                    cellRender={renderActions}
                />
            </GridContainer>
        </>
    )
}

export default connect(state => ({
    getCboAppStatus: state.general.getCboAppStatus,
}), dispatch => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w29F2085Actions: bindActionCreators(W29F2085Actions, dispatch)
}))(W29F2085);