/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/25/2021
 * @Example
 */

import React, {useEffect, useState, useRef} from 'react';
import {bindActionCreators, compose}        from "redux";
import {connect}                            from "react-redux";
import * as generalActions                  from "../../../../redux/general/general_actions";

import * as W29F2086Actions from "../../../../redux/W2X/W29F2086/W29F2086_actions";
import _                    from "lodash";

import {Col, FormGroup, Row} from "react-bootstrap";
import {Column}              from "devextreme-react/data-grid";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ModalHeader,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ButtonIcon,
    TextInput, DatePicker, Attachment,
}                            from "diginet-core-ui/components";
import Config                from "../../../../config";
import CDN                   from "../../../CDN";
import W09F6000              from "../../../W0X/W09/W09F6000/W09F6000";
import GridActionBar         from "../../../grid-container/grid-actionbar";
import GridContainer         from "../../../grid-container/grid-container";
import {MForm}               from "../../../common/form-material";
import HeadClick             from "../../../grid-container/head-click";
import moment                from "moment";
import PropTypes             from "prop-types";

const _initDataForm = {
    TransID       : "",
    AttendanceDate: "",
    Reason        : "",
    Note          : "",
};

const W29F2086 = (props) => {
    const {open, mode, iPermissionW29F2085, data: datForm, onClose} = props;
    const Language                                                  = Config.language || "84";
    const [dataForm, setDataForm]                                   = useState(_initDataForm);
    const [dataGridEmployee, setDataGridEmployee]                   = useState([]);
    const [dataOldAttachments, setDataOldAttachments]               = useState([]);
    const [errors, setErrors]                                       = useState({
        AttendanceDate: ""
    });
    const [isLoadingForm, setLoadingForm]                           = useState(false);
    const [isLoadingGrid, setLoadingGrid]                           = useState(false);
    const [isLoadingAttachment, setLoadingAttachment]               = useState(false);
    const [isShowModalEmployeeChosen, setShowModalEmployeeChosen]   = useState(false);
    const [filterGridValue, setFilterGridValue]                     = useState([]);
    const [isExpand, setIsExpand]                                   = useState(false);

    const editting      = useRef(0);
    const attachments   = useRef([]);
    const deletedFile   = useRef([]);
    const selectedRange = useRef(null);
    const filterColIndx = useRef(null);
    const filterGrid    = useRef({});
    const isChanged     = useRef(false);
    let dataGrid        = useRef(null);
    const timer         = useRef(0);

    useEffect(() => {
        if (mode !== "add") {
            loadMaster();
            loadDetail();
            loadAttachments();
        }
        setIsExpand(mode !== "add");
        return function deleteD09T6668() {
            props.w29f2086Actions.deleteD09T6668(
                { EmployeeID: Config.profile.HREmployeeID, FormID: "W29F2086" },
                error => {
                    if (error) Config.popup.show("ERROR", error);
                }
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isChanged.current) {
            onChooseAttendanDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.AttendanceDate]);

    const loadMaster = () => {
        const params = {
            Language: Language,
            FormID  : "W29F2085",
            TransID : datForm?.TransID ?? "",
            Mode    : 0,
        };
        setLoadingForm(true);
        props.w29f2086Actions.getForm(params, (error, result) => {
            setLoadingForm(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (result) {
                const attendanceDate = moment(result?.[0]?.AttendanceDate ?? "").isValid() ?
                    moment(result?.[0]?.AttendanceDate).format('YYYY-MM-DD') : "";
                setDataForm({
                    TransID       : result?.[0]?.TransID ?? "",
                    AttendanceDate: attendanceDate,
                    Reason        : result?.[0]?.Reason ?? "",
                    Note          : result?.[0]?.Note ?? "",
                })
            }
        })
    };

    const loadDetail = () => {
        const params = {
            Language: Language,
            FormID  : "W29F2085",
            TransID : datForm?.TransID ?? "",
            Mode    : 1,
        };
        setLoadingForm(true);
        props.w29f2086Actions.getForm(params, (error, result) => {
            setLoadingForm(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (result) {
                const dt = result?.map(i => ({
                    ...i,
                    IsUsed: i?.IsUsed ?? false
                })) || [];
                setDataGridEmployee(dt || []);
            }
        })
    };

    const loadAttachments = () => {
        const param = {
            KeyID    : datForm?.TransID ?? "",
            TableName: "D75T4075"
        };
        setLoadingAttachment(true);
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoadingAttachment(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            setDataOldAttachments(data || []);
        });
    };

    const onChosenEmployees = () => {
        if(dataGrid) {
            dataGrid.instance.saveEditData();
            const dataSource = dataGrid.instance.option("dataSource");
            const params     = {
                FormID        : "W29F2086",
                AttendanceDate: dataForm?.AttendanceDate || null,
                Note          : dataForm?.Note ?? "",
                Reason        : dataForm?.Reason ?? "",
                TransID       : datForm?.TransID || "",
            };
            setShowModalEmployeeChosen(false);
            setLoadingGrid(true);
            props.w29f2086Actions.loadChooseEmployee(params, (error, result) => {
                setLoadingGrid(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (result) {
                    let employees    = _.uniqBy([...dataSource, ...result], "EmployeeID");
                    employees        = employees.map(i => ({
                        ...i,
                        IsUsed: i?.IsUsed ?? false
                    }));
                    setDataGridEmployee(employees);
                    setIsExpand(true);
                }
            });
        }
    };

    const onChooseAttendanDate = () => {
        const params    = {
            FormID        : "W29F2086",
            AttendanceDate: dataForm?.AttendanceDate || null,
            Note          : dataForm?.Note ?? "",
            Reason        : dataForm?.Reason ?? "",
            TransID       : datForm?.TransID || "",
        };
        setLoadingGrid(true);
        props.w29f2086Actions.loadChooseEmployee(params, (error, result) => {
            setLoadingGrid(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (result) {
                const dt = result?.map(i => ({
                    ...i,
                    IsUsed: i?.IsUsed ?? false
                })) || [];
                setDataGridEmployee(dt || []);
            }
        });
    };

    const onDeleteRow = (e) => {
        if (!e) return null;
        if(dataGrid) {
            dataGrid.instance.saveEditData();
            const dataSource = dataGrid.instance.option("dataSource");
            const {data}     = e.row;
            const params = {
                FormID: "W29F2085",
                EmployeeID: data?.EmployeeID ?? ""
            };
            Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                setLoadingGrid(true);
                props.w29f2086Actions.deleteRow(params, (error, result) => {
                    setLoadingGrid(false);
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    if (result) {
                        const filterGrid = dataSource.filter(i => i.EmployeeID !== data?.EmployeeID);
                        setDataGridEmployee(filterGrid || []);
                    }
                });
            });
        }
    };

    const onClosePopup = (isReload = false) => {
        if (onClose) onClose(isReload);
    };

    const saveGrid = () => {
        if(dataGrid) {
            dataGrid.instance.saveEditData().done(() => {
                setTimeout(() => {
                    if (editting.current === 0) {
                        onSave();
                    } else {
                        editting.current = 0;
                    }
                }, 200);
            });
        }

    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove    = deletedFile.current.map((d) => d.AttachmentID);
            const dataNew       = [...dataOldAttachments].filter(att => _arrRemove.indexOf(att.AttachmentID) < 0);
            setDataOldAttachments(dataNew);
        }
    };

    const _getAttachments = (file) => {
        const dataFile        = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment     = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL     : att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt : att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _removeCDN = () => {
        if (deletedFile.current && deletedFile.current.length > 0) {
            deletedFile.current.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            deletedFile.current = [];
        }
    };

    const onSave = async () => {
        const dataSource          = dataGrid.instance.option("dataSource");
        const dataT               = JSON.parse(JSON.stringify(dataSource));
        const dataNew             = dataT.map((item) => {
            item.IsUsed = +!!item.IsUsed;
            item.LateMinute = item.LateMinute || 0;
            item.EarlyMinute = item.EarlyMinute || 0;
            item.InLateMinutes = item.InLateMinutes || 0;
            item.OutEarlyMinutes = item.OutEarlyMinutes || 0;
            return item;
        });
        const validateMasterRules = [
            {
                name : "AttendanceDate",
                rules: ["isRequired"],
                value: dataForm.AttendanceDate || ""
            }
        ];
        const validateForm        = MForm.formValidation(validateMasterRules);
        if (Object.keys(validateForm).length > 0) {
            setErrors(validateForm);
            return;
        }

        const dataResUploadFile = await _uploadFile(attachments.current, true);
        const arrAttachments    = _getAttachments(dataResUploadFile);

        if (dataNew.find(i => !i.LateMinute && !i.EarlyMinute && !i.InLateMinutes && !i.OutEarlyMinutes)) {
            const dtGrid = dataNew.filter(i => i.LateMinute || i.EarlyMinute || i.InLateMinutes || i.OutEarlyMinutes);
            Config.popup.show("YES_NO", Config.lang("Nhung_dong_chua_nhap_so_phut_dang_ky_se_bi_xoa.Ban_co_muon_luu_khong"), () => {
                saveData(dtGrid, arrAttachments);
            });
        } else {
            saveData(dataNew, arrAttachments);
        }
    };

    const saveData = (dt = [], arrAttachments = []) => {
        const params = {
            TransID       : datForm?.TransID ?? "",
            AttendanceDate: dataForm?.AttendanceDate || null,
            Reason        : dataForm?.Reason ?? "",
            Note          : dataForm?.Note ?? "",
            Mode          : mode === "add" ? 0 : 1,
            FormID        : "W29F2085",
            Language      : Language,
            ApprovalStatus: 0,
            Data          : dt ? JSON.stringify(dt) : "",
            arrAttachment : JSON.stringify(arrAttachments || [])
        };
        props.w29f2086Actions.save(params, (errors, result) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (result) {
                if (result.Status === 0) {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    onClosePopup(true);
                    _removeCDN();
                } else {
                    const msg = result.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', msg);
                }
            }
        });
    };

    const getFilterGrid = (isClear) => {
        if (isClear) filterGrid.current = {};
        const filter     = filterGrid.current;
        const dataFilter = [];
        Object.keys(filter).forEach(fil => {
            const itemFilter = filter[fil];
            if (itemFilter && itemFilter.length > 0) {
                dataFilter.push(filter[fil]);
                dataFilter.push("and");
            }
        });
        dataFilter.pop();
        return dataFilter;
    };

    const handleChange = (key, e) => {
        if (!key) return false;
        if(!isChanged.current) {
            isChanged.current = true;
        }
        const value       = _.get(e, 'value', _.get(e, 'target.value', ''));
        if (["AttendanceDate"].includes(key)) {
            if (!value || value.length === 0) {
                setErrors({
                    ...errors,
                    [key]: Config.lang('Truong_nay_bat_buoc_nhap')
                });
            } else {
                setErrors({
                    ...errors,
                    [key]: ''
                });
            }
            setDataForm({
                ...dataForm,
                [key]: value
            });
        } else {
            if(timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setDataForm({
                    ...dataForm,
                    [key]: value
                });
            }, 500);
        }
    };

    const renderHeaderColumns = (e) => {
        if (!e) return false;
        return (
            <HeadClick selectedRange={selectedRange.current}
                       dataHeaderCell={e}
                       allowClick={true}
                       conditionCopy={(eChild) => {
                           const {data}     = eChild;
                           const dataSource = dataGrid.instance.option("dataSource");
                           return (dataSource.find(i => !!i.IsUsed) && eChild.key !== "IsUsed") ?
                               data.IsUsed : (!data?.ShiftID ?  data.IsUsed : 1) ;
                       }}
            />
        )
    };

    const renderAction = (e) => {
        return (
            <GridActionBar>
                <ButtonIcon name={"Delete"}
                            viewType={"text"}
                            circular
                            viewBox
                            disabled={mode === 'view'}
                            onClick={() => onDeleteRow(e)}/>
            </GridActionBar>
        )
    };

    const cellRenderShiftID = (e) => {
        if (!e || !e.row) return;
        const {data} = e.row;
        return (
            <>
                {!data?.ShiftID ?
                    <span style={{color: "red"}}>(!)</span>
                    : ""}
            </>
        )
    };

    const onInput = (e) => {
        if(String(e.event.target.value).includes("-") ||
            String(e.event.target.value).includes("e") ||
            String(e.event.target.value).includes("E")) {
            e.event.target.value = e.event.target.value.replace(/[-eE]/, "");
            e.component.option("value", e.event.target.value);
        }
    };

    const calculateCellIsUsed = (data) => {
        return !!data.IsUsed;
    };

    const calculateFilterIsUsed = (filterValue, selectedFilterOperation, target) => {
        if(target === "filterBuilder") {
            return [calculateCellIsUsed, selectedFilterOperation || '=', filterValue];
        }
    };

    const isDisable         = !dataGridEmployee.every(item => item.ShiftID !== "");
    return <>
        {isShowModalEmployeeChosen &&
        <W09F6000
            loadParams={{AttendanceDate: dataForm?.AttendanceDate ?? ""}}
            open={isShowModalEmployeeChosen} dataDefaults={{}}
            FormID={"W29F2085"}
            onChosen={onChosenEmployees}
            onClose={() => setShowModalEmployeeChosen(false)}
        />
        }
        <Modal open={open}
               width={"100%"}
               onClose={() => onClosePopup()}
        >
            <ModalHeader>{Config.lang("Dang_ky_di_tre_ve_som_hang_loat")}</ModalHeader>
            <ModalBody style={{height: "100vh"}}>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <DatePicker
                            error={!dataForm?.AttendanceDate ? errors.AttendanceDate : ""}
                            clearAble
                            required
                            placeholder={'DD/MM/YYYY'}
                            displayFormat={'DD/MM/YYYY'}
                            label={Config.lang("Ngay_cong")}
                            value={dataForm?.AttendanceDate}
                            disabled={mode === "edit" || mode === "view"}
                            onChange={e => handleChange("AttendanceDate", e)}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <TextInput
                            onChange={(e) => handleChange('Reason', e)}
                            value={dataForm?.Reason}
                            label={Config.lang('Ly_do')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            onChange={(e) => handleChange('Note', e)}
                            value={dataForm?.Note}
                            label={Config.lang('Ghi_chu')}
                        />
                    </Col>
                </Row>

                <FormGroup>
                    <Accordion expanded={isExpand} onExpand={() => setIsExpand(true)} onCollapse={() => setIsExpand(false)}>
                        <AccordionSummary>
                            <div style={{width: "100%"}}>
                                <div className={"display_row align-center align-between"}>
                                    {Config.lang("Danh_sach_nhan_vien")}
                                        <Button
                                            color={"primary"}
                                            startIcon={"AddPerson"}
                                            viewType={"outlined"}
                                            disabled={isLoadingGrid || isLoadingForm || iPermissionW29F2085 <= 1 || mode === 'view'}
                                            text={Config.lang('Chon_nhan_vien')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowModalEmployeeChosen(true);
                                            }}
                                        />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Row>
                                <Col xs={12}>
                                    <GridContainer
                                        dataSource={dataGridEmployee}
                                        reference={ref => dataGrid = ref}
                                        keyExpr={"EmployeeID"}
                                        columnAutoWidth={true}
                                        showRowLines={true}
                                        showBorders={false}
                                        showColumnLines={false}
                                        loading={isLoadingGrid}
                                        noDataText={Config.lang("No_data")}
                                        hoverStateEnabled={true}
                                        rowAlternationEnabled={false}
                                        style={{
                                            border   : "none",
                                            overflowX: "scroll"
                                        }}
                                        height={Config.getHeightGrid() - 280}
                                        typePaging={"remote"}
                                        pagerFullScreen={false}
                                        editing={{
                                            mode         : 'cell',
                                            refreshMode  : 'full',
                                            allowUpdating: mode !== "view",
                                        }}
                                        onEditorPreparing={(e) => {
                                            if (!e || !e.row) return;
                                            const {data} = e.row;
                                            if ((e.parentType === "searchPanel" || e.parentType === "filterRow") && e.dataField === "EmployeeID") {
                                                e.updateValueTimeout = 2500;
                                            }
                                            // check disable click row
                                            if (e.parentType === "dataRow" && e.dataField === "IsUsed") {
                                                e.editorOptions.disabled = data?.ShiftID === ""
                                            }
                                            if (e.parentType === "dataRow" && e.dataField === "InLateMinutes") {
                                                e.editorOptions.disabled = data?.InLateTimeIn === ""
                                            }
                                            if (e.parentType === "dataRow" && e.dataField === "OutEarlyMinutes") {
                                                e.editorOptions.disabled = data?.OutEarlyTimeOut === ""
                                            }
                                        }}
                                        filterRow={{
                                            visible             : true,
                                            showOperationChooser: false,
                                        }}
                                        filterPanel={{
                                            visible: true
                                        }}
                                        sorting={{
                                            mode: "none"
                                        }}
                                        filterBuilderPopup={{
                                            onInitialized: (e) => {
                                                e.component.dispose();
                                                e.element.remove();
                                            }
                                        }}
                                        onCellSelectionChanged={(e) => {
                                            if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                                selectedRange.current = e.selectedRange;
                                            }
                                        }}
                                        onRowUpdated={(e) => {
                                            e.data.IsUpdated = 1;
                                        }}
                                        onRowValidating={e => {
                                            editting.current = +!e.isValid;
                                        }}
                                        filterValue={filterGridValue}
                                        onCellPrepared={e => e.separator = ","}
                                        onFilterGrid={(e) => {
                                            let {column, filterValue, columnIndex} = e;
                                            filterColIndx.current                  = columnIndex;
                                            e.component.saveEditData();
                                            if (column) {
                                                if (column.dataField === "EmployeeID") {
                                                    let dataFilter = [];
                                                    if (filterValue) {
                                                        if (filterValue.indexOf(",") > -1) {
                                                            const arrFilters = filterValue.split(",");
                                                            arrFilters.forEach(f => {
                                                                dataFilter.push(["EmployeeID", "=", f.trim()]);
                                                                dataFilter.push("or");
                                                            });
                                                            dataFilter.pop();
                                                        } else {
                                                            dataFilter = ["EmployeeID", "contains", filterValue];
                                                        }
                                                    }
                                                    filterGrid.current.EmployeeID = dataFilter;
                                                } else {
                                                    let operator = "contains";
                                                    if (["IsUsed", "AttendanceDate", "LateMinute", "EarlyMinute", "InLateMinutes", "OutEarlyMinutes"].includes(column.dataField)) operator = "=";
                                                    filterGrid.current[column.dataField] = (filterValue || typeof filterValue === "boolean") ? [column.dataField, operator, filterValue] : [];
                                                }
                                                setFilterGridValue(getFilterGrid());
                                            } else {
                                                filterGrid.current = {};
                                                setFilterGridValue([]);
                                            }
                                        }}
                                    >
                                        <Column width={50}
                                                alignment={"right"}
                                                allowEditing={false}
                                                fixed={true}
                                                cellRender={renderAction}
                                                allowSorting={false}
                                                visible={Config.isMobile}
                                        />
                                        <Column visible={true}
                                                width={50}
                                                fixed={true}
                                                cellRender={cellRenderShiftID}
                                                allowEditing={false}
                                        />
                                        <Column dataField={"LinkTransID"}
                                                visible={false}
                                        />
                                        <Column alignment={"center"}
                                                dataField={"IsUsed"}
                                                caption={Config.lang("Chon")}
                                                trueText={Config.lang("Co")}
                                                falseText={Config.lang("Khong")}
                                                dataType={"boolean"}
                                                headerCellRender={renderHeaderColumns}
                                                calculateCellValue={calculateCellIsUsed}
                                                calculateFilterExpression={calculateFilterIsUsed}
                                                width={100}
                                                fixed={true}
                                        />
                                        <Column caption={Config.lang("Ma_nhan_vien")}
                                                dataField={"EmployeeID"}
                                                width={130}
                                                fixed={true}
                                                allowEditing={false}
                                        />
                                        <Column caption={Config.lang("Ten_nhan_vien")}
                                                dataField={"EmployeeName"}
                                                width={200}
                                                fixed={!Config.isMobile}
                                                allowEditing={false}
                                        />
                                        <Column caption={Config.lang("Ngay_di_ca")}
                                                dataType="date"
                                                format={'dd/MM/yyyy'}
                                                width={180}
                                                dataField={"AttendanceDate"}
                                                allowEditing={false}
                                        />
                                        <Column caption={Config.lang("Ca_vao_ra")}
                                                minWidth={150}
                                                dataField={"ShiftInOut"}
                                                allowEditing={false}
                                        />
                                        <Column caption={Config.lang("Di_tre")}
                                                width={100}
                                                dataField={"LateMinute"}
                                                dataType={"number"}
                                                editorOptions={{
                                                    onInput: (e) => onInput(e),
                                                }}
                                                headerCellRender={renderHeaderColumns}
                                                allowEditing={true}
                                                allowCopying={true}
                                                allowPasting={true}
                                        />
                                        <Column caption={Config.lang("Ve_som")}
                                                width={100}
                                                dataField={"EarlyMinute"}
                                                dataType={"number"}
                                                editorOptions={{
                                                    onInput: (e) => onInput(e),
                                                }}
                                                headerCellRender={renderHeaderColumns}
                                                allowEditing={true}
                                                allowCopying={true}
                                                allowPasting={true}
                                        />
                                        <Column caption={Config.lang("Vao_tre")}
                                                width={100}
                                                dataField={"InLateMinutes"}
                                                dataType={"number"}
                                                editorOptions={{
                                                    onInput: (e) => onInput(e),
                                                }}
                                                headerCellRender={renderHeaderColumns}
                                                allowEditing={true}
                                                allowCopying={true}
                                                allowPasting={true}
                                        />
                                        <Column caption={Config.lang("Ra_som")}
                                                width={100}
                                                dataField={"OutEarlyMinutes"}
                                                dataType={"number"}
                                                editorOptions={{
                                                    onInput: (e) => onInput(e),
                                                }}
                                                headerCellRender={renderHeaderColumns}
                                                allowEditing={true}
                                                allowCopying={true}
                                                allowPasting={true}
                                        />
                                        <Column caption={Config.lang("Ghi_chu")}
                                                dataField={"Note"}
                                                headerCellRender={renderHeaderColumns}
                                                minWidth={200}
                                                allowEditing={true}
                                                allowCopying={true}
                                                allowPasting={true}
                                        />
                                        <Column caption={Config.lang("Ly_do")}
                                                dataField={"Reason"}
                                                headerCellRender={renderHeaderColumns}
                                                minWidth={200}
                                                allowEditing={true}
                                                allowCopying={true}
                                                allowPasting={true}
                                        />
                                        <Column dataField={"LateTimeIn"} visible={false}/>
                                        <Column dataField={"EarLyTimeOut"} visible={false}/>
                                        <Column dataField={"OutEarlyTimeOut"} visible={false}/>
                                        <Column dataField={"InLateTimeIn"} visible={false}/>
                                        <Column alignment={"center"}
                                                fixed={true}
                                                fixedPosition={"right"}
                                                cellRender={renderAction}
                                                width={50}
                                                allowEditing={false}
                                                allowSorting={false}
                                                visible={!Config.isMobile}
                                        />
                                    </GridContainer>
                                </Col>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                <FormGroup>
                    <Accordion expand={false}>
                        <AccordionSummary>
                            {Config.lang("Dinh_kem")}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Attachment
                                maxFile={5}
                                maxSize={"5mb"}
                                multiple
                                unitSize={"mb"}
                                disabled={isLoadingGrid || isLoadingAttachment || isLoadingForm
                                || iPermissionW29F2085 <= 1 || (mode === "edit" && datForm?.AppStatusID !== 0) || mode === 'view'}
                                data={dataOldAttachments}
                                onChange={(e) => {
                                    onChangeAttachments(e)
                                }}
                                uploadErrorInfo={{
                                    existingFile: Config.lang('File_da_duoc_dinh_kem'),
                                    maxFile     : Config.lang('File_vuot_qua_so_luong_cho_phep'),
                                    maxSize     : Config.lang('File_vuot_qua_dung_luong_cho_phep'),
                                    fileType    : Config.lang("File_khong_dung_dinh_dang"),
                                }}

                            />
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button label={Config.lang('Luu')}
                        startIcon={"SaveV2"}
                        color={"info"}
                        disabled={isLoadingGrid || isLoadingAttachment || isLoadingForm ||
                        isDisable || iPermissionW29F2085 <= 1 || mode === 'view'}
                        viewType={"filled"}
                        style={{textTransform: 'uppercase'}}
                        size={"large"}
                        onClick={saveGrid}
                />
            </ModalFooter>
        </Modal>
    </>
};

W29F2086.propTypes = {
    open               : PropTypes.bool,
    mode               : PropTypes.string,
    iPermissionW29F2085: PropTypes.number,
    data               : PropTypes.object,
    onClose            : PropTypes.func,
};

W29F2086.defaultProps = {
    open               : false,
    mode               : "",
    iPermissionW29F2085: 0,
    data               : {},
    onClose            : null,
};
export default compose(connect(null,
    dispatch => (
        {
            generalActions : bindActionCreators(generalActions, dispatch),
            w29f2086Actions: bindActionCreators(W29F2086Actions, dispatch),
        }
    )))(W29F2086);
